import React, { useRef } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import styled from "styled-components";

// This site has 3 pages, all of which are rendered
// dynamically in the browser (not server rendered).
//
// Although the page does not ever refresh, notice how
// React Router keeps the URL up to date as you navigate
// through the site. This preserves the browser history,
// making sure things like the back button and bookmarks
// work properly.

export default function BasicExample() {
  return (
    <Router>
      <div>
        {/*
          A <Switch> looks through all its children <Route>
          elements and renders the first one whose path
          matches the current URL. Use a <Switch> any time
          you have multiple routes, but you want only one
          of them to render at a time
        */}
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

// You can think of these components as "pages"
// in your app.

function Home() {
  const google_form_url =
    "https://docs.google.com/forms/d/e/1FAIpQLScenSm_ChoBR9v992VQH3fKBWSrEMu-mUGK9UWVJLiLnFmYRw";
  const google_preform_url = (email_address) =>
    `${google_form_url}/viewform?usp=pp_url&entry.1203593358=${email_address}`;

  const mailingListEmailEl = useRef(null);

  const StyledDiv = styled.div`
    margin: none;
    font-family: "Utopia", serif;
    #splash {
      min-height: 100vh;
      background: #efeeed;
      display: flex;
      flex-direction: column;
      .what-we-do {
        font-family: "Sang Bleu Kingdom", serif;
        width: 1100px;
        max-width: calc(100% - 70px);
        margin: 0 auto;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: space-between;
        flex-grow: 1;
        line-height: 1.2em;
        color: #c93a18;
        font-size: 45px;
        @media (min-width: 480) {
          font-size: 63px;
        }
        @media (min-width: 800px) {
          font-size: 78px;
        }
      }
    }
    .top {
      background: white;
      width: 100%;
      margin: 0 auto;
      text-align: center;
      a {
        display: inline-block;
        color: white;
        text-decoration: none;
        font-size: 20px;
        font-style: italic;
      }
    }
    nav {
      background: white;
      width: 100%;
      padding: 20px 0px;
      .inner {
        width: 900px;
        max-width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
      }
      .item {
        text-align: center;
        img {
          display: block;
          width: 70px;
          @media (min-width:  450px) {
            width: 142px;
          }
        }
        a {
          font-family: "Apercu", "Utopia", sans-serif;
          font-weight: bold;
          text-decoration: none;
          color: #c93a18;
          font-size: 14px;
        }
      }
    }
    .inner-gradient {
      margin: 0 auto;
      background: rgb(149, 31, 25);
      background: linear-gradient(
        90deg,
        rgba(149, 31, 25, 1) 0%,
        rgba(181, 47, 34, 1) 20%,
        rgba(210, 65, 43, 1) 41%,
        rgba(233, 113, 56, 1) 62%,
        rgba(238, 151, 66, 1) 84%,
        rgba(244, 192, 79, 1) 100%
      );
      padding: 12px;
      .inner-text {
        h2 {
          margin-bottom: 40px;
          text-align: center;
        }
        color: #4c4c4c;
        background: white;
        text-align: center;
        font-size: 25px;
        line-height: 1.6em;
        font-family: "Utopia", serif;
        margin: 10px;
        padding: 50px 30px;
        @media (min-width: 450px) {
          padding: 115px 60px;
        }
      }
      p {
        max-width: 650px;
        margin: 0 auto;
      }
    }
    h2 {
      font-family: "Apercu", "Utopia", sans-serif;
      text-transform: uppercase;
      font-size: 14px;
      color: #c93a18;
    }
    #contact-us,
    #companies,
    #build-with-us {
      font-size: 24px;
      line-height: 1.6em;
      padding-top: 80px;
      padding-bottom: 80px;
      .inner {
        max-width: 750px;
        margin: 0 auto;
        padding: 0 70px;
      }
      p {
        padding: 20px 0;
        color: #4c4c4c;
      }
      a {
        text-decoration: none;
        color: #c93a18;
      }
    }
    #build-with-us {
      background: #f4f4f4;
    }
    #companies {
      .company-list {
        margin-top: 10px;
        display: flex;
        border: 1px solid #dbdbdb;
        /* align-items: stretch; */
        flex-wrap: wrap;
      }
      .company {
        flex-basis: 32%;
        text-align: center;
        padding: 80px 20px;
        box-sizing: border-box;
        border: 1px solid #ddd;
        flex-grow: 1;
        margin-top: -1px;
        margin-left: -1px;
        margin-right: -1px;
        margin-bottom: -1px;
        border-bottom: none;
        border-right: none;
        &:last-of-type {
          border-right: none;
        }
        .logo {
        }
        .name {
          line-height: 1.2em;
          color: #4c4c4c;
        }
        .description {
          padding-top: 0.4em;
          line-height: 1.2em;
          font-size: 13px;
        }
      }
    }
    #contact-us {
      background: #c93a18;
      color: white;
      h2,
      a {
        color: white;
      }
      .inner {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
      }
      .contact-info {
        flex-basis: 300px;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-bottom: 20px;
        @media (min-width: 450px) {
          flex-direction: row;
          margin-bottom: none;
        }
        .letter {
          display: inline-block;
          padding-right: 20px;
          img {
            width: 120px;
          }
        }
      }
      .newsletter {
        flex-basis: 300px;
        font-size: 16px;
        p {
          color: white;
          line-height: 1.2em;
          margin-bottom: 10px;
        }
        input {
          border: none;
          background: white;
          padding: 10px 30px;
          margin-right: 10px;
        }
        button {
          background: white;
          border: none;
          padding: 10px 15px;
          box-sizing: border-box;
          color: #ce4d3b;
          font-weight: bold;
        }
      }
      p {
        padding: 0;
      }
    }
  `;

  return (
    <StyledDiv>
      <div id="splash">
        <div className="top">
          <h1>
            <a href="http://assemb.ly">
              <img alt="Assembly" src="/img/assembly-logo.png" height="42" />
            </a>
          </h1>
        </div>
        <nav>
          <div className="inner">
            <div className="item">
              <a href="#the-why">
                <img src="/img/homepage/the-why.png" />
                The Why
              </a>
            </div>
            <div className="item">
              <a href="#build-with-us">
                <img src="/img/homepage/build-with-us.png" />
                Build With Us
              </a>
            </div>
            <div className="item">
              <a href="#companies">
                <img src="/img/homepage/companies.png" />
                Companies
              </a>
            </div>
            <div className="item">
              <a href="#contact-us">
                <img src="/img/homepage/contact-us.png" />
                Contact Us
              </a>
            </div>
          </div>
        </nav>
        <div className="what-we-do">
          We build and back <br /> future-defining companies
        </div>
      </div>
      <div id="the-why">
        <div className="inner-gradient">
          <div className="inner-text">
            <h2>The Why</h2>
            <p>
              What if countless innovators, with maps to a better future, were
              stuck? Stuck because starting required a team – and not any team,
              but a team of capable believers.
            </p>
            <br />
            <p>Assembly is a venture studio premised on these thoughts.</p>
          </div>
        </div>
      </div>
      <div id="build-with-us">
        <div className="inner">
          <h2>Build With Us</h2>
          <p>Team first – it's an adage all early stage investors believe.</p>

          <p>
            But a corollary to that truth is that early stage investors, who
            only write checks, can only invest in ready-made teams. That's why
            you'll find the check writers sourcing from elite schools,
            prestigious institutes, and all the other tired networks: places of
            privilege where teams form only to elbow out true visionaries.
          </p>

          <p>
            Assembly rejects the status quo. We work with people who know their
            industry, have seen it change, and know that now is the time to
            transform it once again. Together we'll build and back your vision
            preparing your company for high growth.
          </p>

          <p>
            Yes, we're idealists, but we're not naïve. Our team has been in the
            startup and venture capital space since the beginning of our careers
            and are known for our operational impact. With Assembly, we've
            pieced together a dream team of tech, marketing, and financial
            experts to get you off the ground.
          </p>

          <p>
            We don't take pitches, but we love conversations. If talking about
            your industry and where it can go ratchets up our mutual excitement
            for doing things differently, then we're ready to consider working
            together.
          </p>

          <p>
            <a href="mailto:hello@assemb.ly">hello@assemb.ly</a>
          </p>
        </div>
      </div>
      <div id="companies">
        <div className="inner">
          <h2>Companies</h2>
          <div className="company-list">
            <a className="company" href="http://auxpanel.com">
              {/* <div className="logo">
                <img height="60" src="/img/homepage/pracmatic.png" />
              </div> */}
              <div className="name">Auxpanel</div>
              <div className="description">practice growth made automatic</div>
            </a>
            <a className="company" href="http://dotcontract.org">
              {/* <div className="logo">
                <img height="60" src="/img/homepage/perl.png" />
              </div> */}
              <div className="name">DotContract</div>
              <div className="description">evolvable smart contract platform</div>
            </a>
            <a className="company" _href="http://healthbadge.org">
              {/* <div className="logo">
                <img height="60" src="/img/homepage/pracmatic.png" />
              </div> */}
              <div className="name">HealthBadge</div>
              <div className="description">
                simple health status verification
              </div>
            </a>
            <a className="company" _href="http://thefitset.com">
              {/* <div className="logo">
                <img height="60" src="/img/homepage/perl.png" />
              </div> */}
              <div className="name">TheFitSet</div>
              <div className="description">livestreamed fitness for everyone</div>
            </a>

            <div className="company">
              <div className="name">Stealth Marketplace</div>
            </div>

            <div className="company">
              <div className="name">Stealth Consumer</div>
            </div>
          </div>
        </div>
      </div>
      <footer id="contact-us">
        <div className="inner">
          <div className="contact-info">
            <div className="letter">
              <img src="/img/homepage/woodmark.png" />
            </div>
            <a href="mailto:hello@assemb.ly">hello@assemb.ly</a>
          </div>
          <div className="newsletter">
            <h2>Newsletter</h2>
            <p>Please join our newsletter for updates</p>
            <form
              method="POST"
              action=""
              onSubmit={(e) => {
                e.preventDefault();
                if (mailingListEmailEl.current.value) {
                  window.location = google_preform_url(
                    mailingListEmailEl.current.value
                  );
                }
              }}
            >
              <input ref={mailingListEmailEl} placeholder="Email Address" />
              <button type="submit">Sign Up</button>
            </form>
          </div>
        </div>
      </footer>
    </StyledDiv>
  );
}
